import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import Section from '../components/section';
import { OutlineButton } from '../components/button';
import BackgroundImage from 'gatsby-background-image';
import { Horizontal, Column } from '../components/section';
import Title from '../components/title';

const BackgroundContainer = styled.div`
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 1200px;

  > * {
    height: 100%;
  }
`;

const IndexColumn = styled(Column)`
  flex-basis: 70%;
`;

const Index = ({ data }) => (
  <>
    <BackgroundContainer>
      <BackgroundImage
        fluid={
          data.homeJson.backgroundImage
            ? data.homeJson.backgroundImage.childImageSharp.fluid
            : {}
        }
        style={{
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
        }}
      />
    </BackgroundContainer>
    <Layout>
      <Section>
        <Title featured pageTitle={data.homeJson.title} />
        <Horizontal>
          <IndexColumn>
            <div
              dangerouslySetInnerHTML={{
                __html: data.homeJson.content.childMarkdownRemark.html,
              }}
            />
            <OutlineButton to="/contact">Contact</OutlineButton>
            <OutlineButton to="/projects">My work</OutlineButton>
          </IndexColumn>
        </Horizontal>
      </Section>
    </Layout>
  </>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      backgroundImage {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
